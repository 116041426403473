import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import saveCosting from './saveCosting';

export default (proposalId, values) => (dispatch) => {
    let message = i18next.t(
        'When changing the costing method, the product amounts will return to their original configuration',
    );
    if (values.costing_type === 'by_watt')
        message = i18next.t(
            'When changing the costing method, the reference of costs per product will be lost. If the plan does not have the cost per Watt and kWh configured, it will be calculated according to the subtotals of the product costing',
        );
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        messages: [message],
        onSuccess: () => dispatch(saveCosting(proposalId, values)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };
    dispatch(alerts.actions.show(dataAlert));
};
