import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Typography,
} from 'sunwise-ui';

const CostingSelector = ({
    costingType,
    disabled,
    proposalId,
    saveCostingType,
    setCostingType,
}) => {
    const { t } = useTranslation();
    return (
        <Grid item xs={18} md={9} lg={5}>
            <Box
                alignItems="center"
                display="flex"
                gap={2}
                sx={{
                    justifyContent: { xs: 'start', md: 'end' },
                    width: '100%',
                }}
            >
                <Typography noWrap variant="body2">
                    {t('Costing type')}
                </Typography>
                <FormControl size="small">
                    <Select
                        disabled={disabled}
                        onChange={(e) => {
                            saveCostingType(
                                proposalId,
                                {
                                    costing_type: e?.target?.value,
                                },
                                () => setCostingType(e?.target?.value),
                            );
                        }}
                        value={costingType}
                        variant="standard"
                    >
                        <MenuItem value="by_product">
                            {t('Per product')}
                        </MenuItem>
                        <MenuItem value="by_watt">
                            {t('Per Watt y kWh')}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Grid>
    );
};

CostingSelector.propTypes = {
    costingType: PropTypes.string,
    disabled: PropTypes.bool,
    proposalId: PropTypes.string,
    saveCostingType: PropTypes.func,
    setCostingType: PropTypes.func,
};

export default CostingSelector;
