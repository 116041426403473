import React, { useEffect, useState } from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Typography } from '@mui/material';
import toNumber from 'lodash/toNumber';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, Collapse, UiDialog as Dialog } from 'sunwise-ui';

import AccordionCard from 'common/components/accordions/AccordionCard';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import proposalGeneratorAccessoryTable from '../proposalGeneratorAccessoryTable';
import proposalGeneratorAdditionalTable from '../proposalGeneratorAdditionalTable';
import proposalGeneratorEnergyStorageTable from '../proposalGeneratorEnergyStorageTable';
import proposalGeneratorInverterTable from '../proposalGeneratorInverterTable';
import proposalGeneratorPanelTable from '../proposalGeneratorPanelTable';
import proposalGeneratorStructureTable from '../proposalGeneratorStructureTable';
import proposalGeneratorTaxesTable from '../proposalGeneratorTaxesTable';
import proposalGeneratorWorkforceTable from '../proposalGeneratorWorkforceTable';

import * as actions from './actions';
import BulkOperationsCard from './components/BulkOperationsCard';
import CostingForm from './components/CostingForm';
import CostingSelector from './components/CostingSelector';
import DonutChart from './components/DonutChart';
import SeeMoreButton from './components/SeeMoreButton';
import Totals from './components/Totals';
import UpdateProductsForm from './components/UpdateProductsForm';
import { countTotalItems } from './helpers';
import * as selectors from './selectors';

const Container = ({
    bulkAction,
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode,
    finalStoragekWh,
    getPermissionsByCode,
    handleClickBulkItems,
    handleCloseModal,
    initializeCostingForm,
    isFetchingOfferProducts,
    isFetchingProposalCatalogs,
    isGenerated,
    isLoading,
    isLocked,
    isOpenModal,
    isUpdatingAccessories,
    isUpdatingAdditionals,
    isUpdatingInverters,
    isUpdatingPanel,
    isUpdatingStructures,
    isUpdatingTaxes,
    isUpdatingWorkforce,
    offerCosting,
    offerDiscount,
    offerProductsData,
    priceByWatt,
    productsTotals,
    proposalId,
    saveCostingType,
    systemSize,
    totalAccessories,
    totalAdditionals,
    totalEnergyStorage,
    totalInverters,
    totalPanels,
    totalStructure,
    totalWorkforce,
    typeChange,
    updateProductsTotals,
}) => {
    const { t } = useTranslation();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selectionModel, setSelectionModel] = useState({});
    const [costingType, setCostingType] = useState('by_product');

    useEffect(() => {
        updateProductsTotals(offerDiscount);
    }, [offerDiscount, offerProductsData]);

    useEffect(() => {
        initializeCostingForm(offerCosting);
        if (offerCosting?.costing_type)
            setCostingType(offerCosting?.costing_type);
    }, [offerCosting]);

    const {
        profit,
        profitPercentage,
        subtotal,
        subtotalWithDiscount,
        total,
        totalCost,
        totalTaxes,
    } = productsTotals;

    const { canModify: canModifyCost } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION,
    );
    const { canModify: canModifyMargin } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION,
    );

    const isFetching =
        isLoading ||
        isFetchingOfferProducts ||
        isFetchingProposalCatalogs ||
        isUpdatingAccessories ||
        isUpdatingAdditionals ||
        isUpdatingInverters ||
        isUpdatingPanel ||
        isUpdatingStructures ||
        isUpdatingTaxes ||
        isUpdatingWorkforce;

    const isLockedForm = isFetching || isLocked;
    const isProductCosting = costingType === 'by_product';

    const getModalTitle = () => {
        switch (bulkAction) {
            case 'discount':
                return t('Exchange price by discount');
            case 'cost':
                return t('Exchange price by cost per watt');
            default:
                return t('Exchange price by margin');
        }
    };

    return (
        <AccordionCard
            cardId="proposal_generator_quote_card"
            customTitle={
                <Grid
                    alignItems="center"
                    container
                    justifyContent="space-between"
                    spacing={0}
                >
                    <Grid item xs={18} lg={9}>
                        <Box alignItems="center" display="flex">
                            <AttachMoneyIcon sx={{ color: 'primary.main' }} />
                            <Typography fontWeight="bold" variant="body2">
                                {t('Quotation')}
                            </Typography>
                        </Box>
                    </Grid>
                    <BulkOperationsCard
                        costingType={costingType}
                        handleClickBulkItems={handleClickBulkItems}
                        proposalId={proposalId}
                        selectionModel={selectionModel}
                        setSelectionModel={setSelectionModel}
                        totalItems={countTotalItems(selectionModel)}
                    />
                    <CostingSelector
                        costingType={costingType}
                        disabled={isGenerated}
                        proposalId={proposalId}
                        saveCostingType={saveCostingType}
                        setCostingType={setCostingType}
                    />
                </Grid>
            }
            classNameCard="__userguiding_proposal_generator_quote_card"
            defaultBehavior={false}
            defaultExpanded={true}
            isLoading={isFetching}
            sxAccordionSummary={{
                '&.Mui-expanded': {
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    minHeight: '70px',
                },
            }}
        >
            {!isProductCosting && (
                <CostingForm
                    constingRanges={offerCosting?.costing_ranges}
                    costingType={costingType}
                    disabled={isGenerated}
                    currencyIso={currencyIso}
                    finalStoragekWh={finalStoragekWh}
                    proposalId={proposalId}
                    systemSize={systemSize}
                    totalCost={totalCost}
                    typeChange={typeChange}
                />
            )}
            <proposalGeneratorPanelTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                isProductCosting={isProductCosting}
                proposalId={proposalId}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                typeChange={typeChange}
            />

            <proposalGeneratorInverterTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                isProductCosting={isProductCosting}
                proposalId={proposalId}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
            />

            <proposalGeneratorEnergyStorageTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                isProductCosting={isProductCosting}
                proposalId={proposalId}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                typeChange={typeChange}
            />

            <proposalGeneratorAccessoryTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                isProductCosting={isProductCosting}
                proposalId={proposalId}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
            />

            <proposalGeneratorStructureTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                isProductCosting={isProductCosting}
                proposalId={proposalId}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
            />

            <proposalGeneratorWorkforceTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                isProductCosting={isProductCosting}
                proposalId={proposalId}
                setSelectionModel={setSelectionModel}
            />

            <proposalGeneratorAdditionalTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                isProductCosting={isProductCosting}
                proposalId={proposalId}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
            />

            <Totals
                canModifyCost={canModifyCost}
                canModifyMargin={canModifyMargin}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isFetching={isFetching}
                isLocked={isLocked}
                offerDiscount={offerDiscount}
                profit={profit}
                profitPercentage={toNumber(profitPercentage)}
                proposalId={proposalId}
                subtotal={subtotal}
                subtotalWithDiscount={subtotalWithDiscount}
                total={total}
                totalCost={totalCost}
                totalTaxes={totalTaxes}
            />
            <Collapse in={isCollapsed}>
                <DonutChart
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    isFetching={isFetching}
                    priceByWatt={priceByWatt}
                    systemSize={systemSize}
                    totalAccessories={totalAccessories}
                    totalAdditionals={totalAdditionals}
                    totalEnergyStorage={totalEnergyStorage}
                    totalInverters={totalInverters}
                    totalPanels={totalPanels}
                    totalStructure={totalStructure}
                    totalWorkforce={totalWorkforce}
                    typeChange={typeChange}
                />
            </Collapse>
            <SeeMoreButton
                isCollapsed={isCollapsed}
                label={isCollapsed ? t('Hide chart') : t('See chart')}
                setIsCollapsed={setIsCollapsed}
            />
            <Dialog
                onClose={() => handleCloseModal()}
                open={isOpenModal}
                size="sm"
                title={getModalTitle()}
            >
                <UpdateProductsForm proposalId={proposalId} />
            </Dialog>
        </AccordionCard>
    );
};

const mapStateToProps = createStructuredSelector({
    bulkAction: selectors.getBulkAction,
    isFetchingOfferProducts: selectors.getIsFetchingOfferProducts,
    isFetchingProposalCatalogs: selectors.getIsFetchingProposalCatalogs,
    isOpenModal: selectors.getIsOpenModal,
    isUpdatingAccessories:
        proposalGeneratorAccessoryTable.selectors.getIsUpdating,
    isUpdatingAdditionals:
        proposalGeneratorAdditionalTable.selectors.getIsUpdating,
    isUpdatingInverters: proposalGeneratorInverterTable.selectors.getIsUpdating,
    isUpdatingPanel: proposalGeneratorPanelTable.selectors.getIsSaving,
    isUpdatingStructures:
        proposalGeneratorStructureTable.selectors.getIsUpdating,
    isUpdatingTaxes: proposalGeneratorTaxesTable.selectors.getIsSaving,
    isUpdatingWorkforce:
        proposalGeneratorWorkforceTable.selectors.getIsUpdating,
    offerProductsData: selectors.getOfferProductsData,
    productsTotals: selectors.getProductsTotals,
    totalAccessories: selectors.getFinalCostAccessories,
    totalAdditionals: selectors.getFinalCostAdditionals,
    totalEnergyStorage: selectors.getFinalCostEnergyStorage,
    totalInverters: selectors.getFinalCostInverters,
    totalPanels: selectors.getFinalCostPanels,
    totalStructure: selectors.getFinalCostStructures,
    totalWorkforce: selectors.getFinalCostWorkforce,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickBulkItems: (action, proposalId, selectionModel, callback) =>
        dispatch(
            actions.prepareUpdateProducts(
                action,
                proposalId,
                selectionModel,
                callback,
            ),
        ),
    handleCloseModal: () => dispatch(actions.closeModal()),
    initializeCostingForm: (values) =>
        dispatch(actions.initializeCostingForm(values)),
    saveCostingType: (proposalId, values) =>
        dispatch(actions.prepareUpdateCostingType(proposalId, values)),
    updateProductsTotals: (offerDiscount) =>
        dispatch(actions.updateProductsTotals(offerDiscount)),
});

Container.propTypes = {
    bulkAction: PropTypes.string,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    finalStoragekWh: PropTypes.number,
    getPermissionsByCode: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleCloseModal: PropTypes.func,
    initializeCostingForm: PropTypes.func,
    isFetchingOfferProducts: PropTypes.bool,
    isFetchingProposalCatalogs: PropTypes.bool,
    isGenerated: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    isUpdatingAccessories: PropTypes.bool,
    isUpdatingAdditionals: PropTypes.bool,
    isUpdatingInverters: PropTypes.bool,
    isUpdatingPanel: PropTypes.bool,
    isUpdatingStructures: PropTypes.bool,
    isUpdatingTaxes: PropTypes.bool,
    isUpdatingWorkforce: PropTypes.bool,
    offerCosting: PropTypes.object,
    offerDiscount: PropTypes.object,
    offerProductsData: PropTypes.object,
    priceByWatt: PropTypes.number,
    productsTotals: PropTypes.object,
    proposalId: PropTypes.string,
    saveCostingType: PropTypes.func,
    systemSize: PropTypes.number,
    total: PropTypes.number,
    totalAccessories: PropTypes.number,
    totalAdditionals: PropTypes.number,
    totalEnergyStorage: PropTypes.number,
    totalInverters: PropTypes.number,
    totalPanels: PropTypes.number,
    totalStructure: PropTypes.number,
    totalWorkforce: PropTypes.number,
    typeChange: PropTypes.number,
    updateProductsTotals: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION,
        PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION,
    ]),
)(Container);
