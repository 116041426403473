import React, { useCallback, useEffect } from 'react';

import { debounce, get, isNumber } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Divider, Grid, Typography } from 'sunwise-ui';

import ReactHookFormIntlNumberInput from 'common/components/form/bootstrap/ReactHookFormIntlNumberInput';
import { DEBOUNCE_TIME } from 'common/constants';
import {
    calculateFinalCost,
    calculateMargin,
    getDecimals,
    getLabelWithCurrency,
} from 'common/utils/helpers';

import * as actions from '../actions';
import { calculateTotal } from '../helpers';
import * as selectors from '../selectors';

import PriceRangeDropDown from './PriceRangeDropDown';

const CostingForm = ({
    constingRanges,
    currencyIso,
    disabled,
    finalStoragekWh,
    initialValues,
    isSaving,
    proposalId,
    save,
    systemSize,
    totalCost,
    typeChange,
}) => {
    const { t } = useTranslation();
    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnSave = (values) => save(proposalId, values);

    const handleOnChange = handleSubmit(handleOnSave);

    const typeChangeValue = currencyIso === 'USD' ? 1 : typeChange;

    const handleChangeCostValue = (prefix) => {
        const value = getValues(`${prefix}_cost`);
        if (!value) return false;

        const finalPrice = calculateFinalCost(
            !isNumber(parseInt(value)) ? 0 : value,
            getValues(`${prefix}_margin`),
        );

        setValue(`${prefix}_price`, finalPrice);
        setValue(
            `${prefix}_total_amount`,
            calculateTotal({
                finalPrice,
                finalStoragekWh,
                prefix,
                systemSize,
                typeChangeValue,
            }),
        );
        handleOnChange();
    };

    const handleChangeMarginValue = (prefix) => {
        let value = getValues(`${prefix}_margin`);
        if (!value) return false;

        if (parseFloat(value) >= 100) {
            setValue(`${prefix}_margin`, 99.99);
            value = 99.99;
        }

        const finalPrice = calculateFinalCost(
            getValues(`${prefix}_cost`),
            !isNumber(parseInt(value)) ? 0 : value,
        );

        setValue(`${prefix}_price`, finalPrice);
        setValue(
            `${prefix}_total_amount`,
            calculateTotal({
                finalPrice,
                finalStoragekWh,
                prefix,
                systemSize,
                typeChangeValue,
            }),
        );
        handleOnChange();
    };

    const handleChangeFinalCostValue = (prefix) => {
        const value = getValues(`${prefix}_price`);
        if (!value) return false;

        let costValue = getValues(`${prefix}_cost`);

        if (!value || parseFloat(value) < parseFloat(costValue)) {
            setValue(`${prefix}_price`, costValue);
            setValue(`${prefix}_margin`, 0);
        } else if (parseFloat(costValue) === 0) {
            setValue(`${prefix}_margin`, 0);
            setValue(`${prefix}_cost`, value);
        } else if (calculateMargin(value, costValue) >= 100) {
            setValue(`${prefix}_margin`, 99.99);
            setValue(`${prefix}_price`, calculateFinalCost(costValue, 99.99));
        } else setValue(`${prefix}_margin`, calculateMargin(value, costValue));
        setValue(
            `${prefix}_total_amount`,
            calculateTotal({
                finalPrice: value,
                finalStoragekWh,
                prefix,
                systemSize,
                typeChangeValue,
            }),
        );
        handleOnChange();
    };

    const handleChangeTotalValue = (prefix) => {
        const value = getValues(`${prefix}_total_amount`);
        if (!value) return false;

        const costValue = getValues(`${prefix}_cost`);
        const totalSize =
            prefix === 'watt' ? systemSize * 1000 : finalStoragekWh;

        if (value > totalCost) {
            const newPrice = value / (totalSize * typeChangeValue);
            const newMargin = (1 - costValue / newPrice) * 100;

            setValue(
                `${prefix}_price`,
                newPrice.toFixed(getDecimals(newPrice)),
            );
            setValue(`${prefix}_margin`, newMargin.toFixed(2));
        } else {
            const newCost = value / (totalSize * typeChangeValue);
            setValue(`${prefix}_cost`, newCost.toFixed(getDecimals(newCost)));
            setValue(`${prefix}_margin`, 0);
            setValue(`${prefix}_price`, calculateFinalCost(newCost, 0));
        }
        handleOnChange();
    };

    const handleDebouncedChange = useCallback(
        debounce((prefix, eventName) => {
            switch (eventName) {
                case 'margin':
                    handleChangeMarginValue(prefix);
                    break;
                case 'price':
                    handleChangeFinalCostValue(prefix);
                    break;
                case 'total':
                    handleChangeTotalValue(prefix);
                    break;
                default:
                    handleChangeCostValue(prefix);
                    break;
            }
        }, DEBOUNCE_TIME),
        [],
    );

    return (
        <Box>
            <Grid container mb={2}>
                <Grid
                    item
                    xs={18}
                    lg={2}
                    sx={{ textAlign: { xs: 'left', lg: 'center' } }}
                >
                    <Typography fontWeight="bold" variant="body2">
                        {t('Per Watt')}
                    </Typography>
                </Grid>
                <Grid item xs={18} md={9} lg={4}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="USD/W"
                        control={control}
                        disabled={disabled || isSaving}
                        fullWidth
                        label={getLabelWithCurrency('USD', t('Cost'))}
                        min="0"
                        name="watt_cost"
                        onChange={() => handleDebouncedChange('watt', 'cost')}
                        prepend="$"
                    />
                </Grid>
                <Grid item xs={18} md={9} lg={4}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        disabled={disabled || isSaving}
                        fullWidth
                        label={t('Margin')}
                        min="0"
                        name="watt_margin"
                        onChange={() => handleDebouncedChange('watt', 'margin')}
                    />
                </Grid>
                <Grid item xs={18} md={9} lg={4}>
                    <Box alignItems="end" display="flex" sx={{ width: '100%' }}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append="USD/W"
                            control={control}
                            disabled={disabled || isSaving}
                            fullWidth
                            label={getLabelWithCurrency('USD', t('Price'))}
                            name="watt_price"
                            min="0"
                            onChange={() =>
                                handleDebouncedChange('watt', 'price')
                            }
                            prepend="$"
                        />
                        <PriceRangeDropDown
                            ranges={get(constingRanges, 'cost_ranges_watt', [])}
                        />
                    </Box>
                </Grid>
                <Grid item xs={18} md={9} lg={4}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        control={control}
                        disabled={disabled || isSaving}
                        fullWidth
                        label={getLabelWithCurrency(currencyIso, t('Total'))}
                        name="watt_total_amount"
                        min="0"
                        onChange={() => handleDebouncedChange('watt', 'total')}
                        prepend="$"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid
                    item
                    xs={18}
                    lg={2}
                    sx={{ textAlign: { xs: 'left', lg: 'center' } }}
                >
                    <Typography fontWeight="bold" variant="body2">
                        {t('Per kWh')}
                    </Typography>
                </Grid>
                <Grid item xs={18} md={9} lg={4}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="USD/kWh"
                        control={control}
                        disabled={disabled || isSaving}
                        fullWidth
                        label={getLabelWithCurrency('USD', t('Cost'))}
                        min="0"
                        name="kwh_cost"
                        onChange={() => handleDebouncedChange('kwh', 'cost')}
                        prepend="$"
                    />
                </Grid>
                <Grid item xs={18} md={9} lg={4}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        disabled={disabled || isSaving}
                        fullWidth
                        label={t('Margin')}
                        min="0"
                        name="kwh_margin"
                        onChange={() => handleDebouncedChange('kwh', 'margin')}
                    />
                </Grid>
                <Grid item xs={18} md={9} lg={4} sx={{ pl: 0 }}>
                    <Box alignItems="end" display="flex" sx={{ width: '100%' }}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append="USD/kWh"
                            control={control}
                            disabled={disabled || isSaving}
                            fullWidth
                            label={getLabelWithCurrency('USD', t('Price'))}
                            name="kwh_price"
                            min="0"
                            onChange={() =>
                                handleDebouncedChange('kwh', 'price')
                            }
                            prepend="$"
                        />
                        <PriceRangeDropDown
                            ranges={get(constingRanges, 'cost_ranges_kwh', [])}
                        />
                    </Box>
                </Grid>
                <Grid item xs={18} md={9} lg={4}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        control={control}
                        disabled={disabled || isSaving}
                        fullWidth
                        label={getLabelWithCurrency(currencyIso, t('Total'))}
                        name="kwh_total_amount"
                        min="0"
                        onChange={() => handleDebouncedChange('kwh', 'total')}
                        prepend="$"
                    />
                </Grid>
                <Grid item xs={18}>
                    <Divider />
                </Grid>
            </Grid>
            <Divider />
        </Box>
    );
};

CostingForm.propTypes = {
    constingRanges: PropTypes.object,
    currencyIso: PropTypes.string,
    disabled: PropTypes.bool,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    proposalId: PropTypes.string,
    save: PropTypes.func,
    finalStoragekWh: PropTypes.number,
    systemSize: PropTypes.number,
    totalCost: PropTypes.number,
    typeChange: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialCostingValues,
    isSaving: selectors.getIsSavingCosting,
});

const mapDispatchToProps = (dispatch) => ({
    save: (proposalId, values) =>
        dispatch(actions.saveCosting(proposalId, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CostingForm);
