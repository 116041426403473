import { updateOferCosting } from 'common/api/v2/offerCosting';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import {
    SAVE_COSTING,
    SAVE_COSTING_SUCCESS,
    SAVE_COSTING_FAILURE,
} from '../actionTypes';
import { proposalGeneratorQuoteActions } from '../reducer';

import resetCostingForm from './resetCostingForm';

export default (proposalId, values, callback = null) =>
    (dispatch) => {
        dispatch(proposalGeneratorQuoteActions[SAVE_COSTING]());

        updateOferCosting(proposalId, values)
            .then((response) => {
                dispatch(
                    proposalGeneratorQuoteActions[SAVE_COSTING_SUCCESS](
                        response.data.data,
                    ),
                );
                dispatch(resetCostingForm());
                dispatch(eventsAfterUpdate(response.data.data, proposalId));
                if (callback) callback();
            })
            .catch((error) => {
                dispatch(
                    proposalGeneratorQuoteActions[SAVE_COSTING_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
